@tailwind base; /* Preflight will be injected here */

@layer base {
  a {
    @apply no-underline;
  }
}

@tailwind components;

@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: fit-content !important;
}

video {
  object-fit: cover;
}

/* html, body, #root, .App {
  height: fit-content !important;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


